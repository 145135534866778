var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-scroll-x-box menu-silder"},[_c('div',{staticClass:"mind-class-content clearfix"},_vm._l((_vm.data),function(each,index){return _c('div',{key:index,staticClass:"modal-box-children",on:{"click":function($event){return _vm.clickCreated(each, index)}}},[_c('div',{staticClass:"gray-box"}),(
          !(each.layoutType == 4 && each.mindType == 1 && each.isEmpty == true)
          
        )?_c('div',{style:('width: 132px;height: 80px;background:url(' +
          each.icon +
          ') no-repeat;background-size: 1320px 800px;background-position:center;overflow:hidden;')}):_c('div',{style:('width: 1320px;height: 80px;background:url(' +
          each.icon +
          ') no-repeat;background-size: cover;background-position:center;overflow:hidden;')},[_c('div',{staticClass:"empty-text"},[_vm._v(_vm._s(_vm.getString(_vm.strings.My_Mind_Blank_Mind_Map)))])]),((each.explain != null && each.explain.length > 0))?_c('div',{staticClass:"item-explain"},[_vm._v(" {each.explain} ")]):_vm._e(),_c('div',{staticClass:"item-explain"},[_vm._v(" asdadasd ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }